<template>
	<div>
		<div class="search" v-show="searchShow">
			<div class="enter">
				<input type="text" @focus="focu" placeholder="请输入关键字搜索" v-model.trim="keyWord" class="input" />
				<button class="enter-btn" @click="search">搜索</button>
				<span class="advanced-query" v-show="advancedQueryShow" @click="advancedQuery">高级查询</span>
			</div>
			<!-- <span
        class="history-query"
        v-show="historyQueryShow"
        @click="historyQuery"
        >历史记录查询</span
      > -->
			<!-- <div class="history-query" v-else></div> -->
		</div>
		<router-view ref="child"></router-view>
		<Footer />
		<el-dialog title="" :visible.sync="dialogVisible" width="1200px" :before-close="handleDialogClose">
			<div class="header">
				<img src="../../assets/images/square/ffcx.png" width="1140px; height:290px; " alt="" />
			</div>
			<div class="dialog-title">
				<img src="../../assets/images/square/ts.png" alt=""
					style="margin-right: 5px; width: 25px; height: 25px" />
				正在【数据广场】-付费查询
			</div>
			<div style="width: 1140px; height: 0px; border: 1px solid #eeeeee"></div>
			<div class="dialog-body">
				<p style="
            margin: 30px 15px 43px 15px;
            font-size: 20px;
            color: #222222;
            font-weight: 400;
          ">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为保证您阅读流畅，【数据广场】的付费项目将绑定你的钱包，在付费前给出支付提示并自动从钱包中扣除，<br />下面将绑定你的钱包：
				</p>
				<div class="pay">
					<span style="color: #e40012">*</span><label>支付方式</label>
					<div class="wallet" :style="
              payType
                ? 'border: 1px solid #E40012;'
                : 'border: 1px solid #eaeaea;'
            ">
						<img v-show="payType" style="width: 16px; height: 16px" src="../../assets/images/square/xz.png"
							alt="" @click="payType = false" />
						<div v-show="!payType" class="circle" @click="payType = true"></div>
						<img style="width: 20.11px; height: 19.45px; margin-left: 28px"
							src="../../assets/images/square/wdqb.png" alt="" />
						<span style="color: #222222; margin-left: 11px">我的钱包</span>
						<span style="font-size: 14px; margin-left: 20px">余额{{ info.balance }}元</span>
						<span style="margin-left: auto">平台</span>
					</div>
					<span style="color: #e40012">*</span><label>支付密码</label>
					<div class="payPassword">
						<input ref="payPwd" @input="ipt(index)" @keydown.delete.prevent="iptDel(index)"
							v-model="password[index]" v-for="(item, index) in password" :key="index" maxlength="1"
							type="password" class="item" />
					</div>
					<span style="color: #00a71c">你的隐私正在保护中...</span>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button class="dialog-btn" @click="bindImmediately">立即绑定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import Footer from "../../components/footer.vue";
	export default {

		components: {
			Footer,
		},
		name: "QppIndex",
		data() {
			return {
				keyWord: "",
				dialogVisible: false,
				payType: false,
				password: ["", "", "", "", "", ""],
				isBindWallet: false,
				info: "",
			};
		},
		watch: {
			// password: {
			//     deep: false,
			//     handler(){
			//         // console.log(this.$refs.payPwd)
			//         // 输入后聚焦到下一input
			//         for(let i = 0; i < this.password.length; i++){
			//             if(this.password[i] === ''){
			//                 this.$refs.payPwd[i].focus()
			//                 break;
			//             }
			//         }
			//     }
			// }
		},
		mounted() {
			this.getInfo();
		},
		computed: {
			searchShow() {
				if (this.$route.meta.title !== "资讯") return true;
				else return false;
			},
			historyQueryShow() {
				if (
					this.$route.meta.title !== "历史记录" &&
					this.$route.meta.title !== "高级查询"
				)
					return true;
				else return false;
			},
			advancedQueryShow() {
				if (
					this.$route.meta.title !== "高级查询" &&
					this.$route.meta.title !== "查询结果"
				)
					return true;
				else return false;
			},
		},
		methods: {
			//聚焦事件
			focu() {
				if (this.$route.meta.title === "数据广场-模糊查询") {
					this.$router.replace('advancedQuery')
					// this.$router.go(-1)
				}

			},
			getInfo() {
				this.$util.post("/user/info").then((res) => {
					console.log(res);

					this.info = res.data;
				});
			},
			search() {
				console.log(this.$route.meta.title);

				// if (this.isBindWallet) {
				// if (this.$route.meta.title === '模糊查询') return
				if (this.keyWord == "") return this.$message.error("请输入搜索关键字");
				if (!this.isBindWallet) {
					return this.dialogVisible = true;
				}
				if (
					this.$route.meta.title === "模糊查询" ||
					this.$route.meta.title === "数据广场" ||
					this.$route.meta.title === "数据广场-高级查询" ||
					this.$route.meta.title === "数据广场-查询结果" ||
					this.$route.meta.title === "数据广场-模糊查询"
				) {
					// this.reload()
					this.$router.push({
						path: "/blurry",
						query: {
							key: this.keyWord,
						},
					});
					sessionStorage.setItem('list', '')
					sessionStorage.setItem('total', '')
					// this.$nextTick(() => {
					//     this.$refs["child"].allData(1);
					// });
				}
				// } else {
				//     // 首次点击绑定钱包
				//     this.dialogVisible = true
				// }
			},
			historyQuery() {
				this.$router.push({
					path: "/historyQuery",
				});
			},
			advancedQuery() {
				if (this.isBindWallet) {
					this.$router.push({
						path: "/advancedQuery",
					});
				} else {
					// 首次点击绑定钱包
					this.dialogVisible = true;
				}
			},
			bindImmediately() {
				var pwd = "";
				if (this.password.length == 0) {
					return this.$message.error("请输入支付密码");
				}
				for (var i in this.password) {
					pwd += this.password[i];
				}

				this.$util
					.post("user/checkPayPsd", {
						psd: pwd,
					})
					.then((res) => {
						console.log(res.code);
						if (res.code == 200) {
							sessionStorage.setItem("pwd", pwd);
							this.isBindWallet = true;
							this.$message({
								message: "绑定成功!现在试试搜索关键字查询吧",
								type: "success",
							});
							this.dialogVisible = false;
						} else {
							return this.$message.error("密码有误");
						}
					});
				// 绑定钱包设置为true
			},
			handleDialogClose(done) {
				this.$confirm("确认关闭？")
					.then((_) => {
						done();
					})
					.catch((_) => {});
			},
			ipt(index) {
				// 输入后聚焦到下一input
				if (index != 5) this.$refs.payPwd[index + 1].focus();
			},
			iptDel(index) {
				this.password.splice(index, 1, "");
				// 删除后聚焦前一input
				this.$nextTick(() => {
					if (index != 0) this.$refs.payPwd[index - 1].focus();
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	/deep/.el-dialog__body {
		padding: 0 20px;
		padding-top: 10px;
	}

	.dialog-title {
		display: flex;
		align-items: center;
		font-size: 22px;
		font-weight: 400;
		color: #333333;
		margin-bottom: 10px;
		margin-top: 60px;
	}

	.dialog-body {
		.pay {
			font-size: 16px;
			font-weight: 400;
			color: #999999;

			.wallet {
				box-sizing: border-box;
				width: 458px;
				height: 50px;
				border: 1px solid #e40012;
				display: flex;
				align-items: center;
				padding: 0 23px;
				margin: 30px 0;
			}

			.circle {
				box-sizing: border-box;
				width: 16px;
				height: 16px;
				border: 1px solid #eaeaea;
				border-radius: 8px;

				&:hover {
					border: 1px solid #e40012;
				}
			}

			.payPassword {
				margin: 30px 0 70px 0;

				.item {
					width: 64px;
					height: 64px;
					text-align: center;
					font-size: 50px;
					background: #ffffff;
					border: 1px solid #d1d1d1;
					margin-right: 30px;
					// outline-color: #E40012;
				}
			}
		}
	}

	.dialog-footer {
		display: flex;
		justify-content: center;
		padding: 0 293px;
		margin-bottom: 80px;
		margin-top: 44px;
	}

	.dialog-btn {
		width: 415px;
		height: 50px;
		background: #e40012;
		font-size: 18px;
		font-weight: 400;
		color: #ffffff;
	}

	.search {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 1172px;
		margin: 0 auto 61px;

		.enter {
			position: relative;
			width: 820px;
			height: 50px;
			display: flex;
			align-items: center;
			border: 1px solid #e40012;

			.input {
				flex: 1;
				padding: 0 30px;
				height: 36px;
				border: none;
				outline: none;
			}

			.enter-btn {
				width: 150px;
				height: 100%;
				background: #e40012;
				border: none;
				cursor: pointer;
				color: #fff;
				font-size: 16px;
			}
		}

		.history-query {
			width: 96px;
			font-size: 16px;
			font-weight: 400;
			color: #999999;
			text-decoration: underline;
			margin-left: 76px;
			cursor: pointer;
		}
	}

	.advanced-query {
		position: absolute;
		left: 5px;
		bottom: -28px;
		font-size: 16px;
		font-weight: 400;
		color: #e40012;
		cursor: pointer;
	}
</style>
